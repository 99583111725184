import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/mdxPageLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`My name is Pedro Lamas, I’m a Portuguese software development consultant specialized in Microsoft technologies living in London `}<abbr parentName="p" {...{
        "title": "United Kingdom"
      }}>{`UK`}</abbr>{`, and this is my blog where I regularly write mostly about Windows Development.`}</p>
    <p>{`I’m a `}<a parentName="p" {...{
        "href": "mcp-transcript"
      }}>{`Microsoft Certified Professional`}</a>{`, a `}<a parentName="p" {...{
        "href": "/2017/07/24/xamarin-certified-mobile-developer"
      }}>{`Xamarin Certified Mobile Developer`}</a>{`, and a former `}<a parentName="p" {...{
        "href": "/2017/03/02/awarded-microsoft-windows-development-mvp-2017"
      }}>{`Microsoft Windows Development `}<abbr parentName="a" {...{
          "title": "Most Valuable Professional"
        }}>{`MVP`}</abbr>{``}</a>{`!`}</p>
    <p>{`I like contributing to all types of open source software, and I’m the author of the `}<a parentName="p" {...{
        "href": "http://cimbalino.org"
      }}>{`Cimbalino Toolkit`}</a>{`.`}</p>
    <p>{`I just love telling people how .NET is the best thing just after freshly brewed coffee!`}</p>
    <h2>{`You might also want to check…`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "mcp-transcript"
        }}>{`Microsoft Certified Professional (`}<abbr parentName="a" {...{
            "title": "Microsoft Certified Professional"
          }}>{`MCP`}</abbr>{`) Transcript`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "pgp-public-key"
        }}>{``}<abbr parentName="a" {...{
            "title": "Pretty Good Privacy"
          }}>{`PGP`}</abbr>{` Public Key`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      